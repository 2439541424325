export default {
  brandPrimary: "#5b2ad0",
  brandInfo: "#0089FF",
  brandSuccess: "#00CC8B",
  brandDanger: "#FF00A7",
  brandWarning: "#FF6A00",
  brandDark: "rgba(91,42,208,1)",
  brandSecondary: "#C3C5C7",
  brandLight: "#FBFAFF",
}
