import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo-w.svg"

const Logo = ({ siteTitle }) => (
  <h1 style={{ margin: 0, textAlign: `center` }}>
    <Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
        backgroundImage: `none`,
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          marginBottom: 0,
        }}
      />
    </Link>
  </h1>
)

Logo.propTypes = {
  siteTitle: PropTypes.string,
}

Logo.defaultProps = {
  siteTitle: ``,
}

export default Logo
