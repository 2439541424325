import React from "react"
import { Button, Typography, Row, Col } from "antd"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import Colors from "../utils/colors"
import banner from "../images/banner.svg"

const { Title } = Typography

const Banner = props => {
  console.log(props)
  const { title, image, link, cta } = props
  return (
    <div className="welcome-banner">
      <Row gutter={16}>
        <Col sm={24} md={10}>
          <div style={styles.bannerTextWrap}>
            <Title className="banner-title" style={styles.bannerTitle}>
              {title ? title : "¿Estás lista para empezar a vender en línea?"}
            </Title>
            {/*<Button
              className="banner-button"
              style={styles.bannerButton}
              size="large"
              onClick={() => {
                window.analytics.track("Download from banner link")
                typeof window !== "undefined" &&
                  window.gtag("event", "Download from banner link")
                window.fbq("track", "Download from banner link", {
                  content_name: "Landing",
                })
              }}
            >
              <OutboundLink
                href={link ? link : "http://bit.ly/elenas-landing"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cta ? cta : "¡Comenzar ahora!"}
              </OutboundLink>
            </Button>*/}
            <Button
              className="banner-button"
              style={styles.bannerButton}
              size="large"
              onClick={() => {
                props.handleScroll()
                window.analytics.track("Scroll down from banner")
                typeof window !== "undefined" &&
                  window.gtag("event", "Scroll down from banner")
                window.fbq("track", "Scroll down from banner", {
                  content_name: "Landing",
                })
              }}
            >
              {cta ? cta : "¡Comenzar ahora!"}
            </Button>
          </div>
        </Col>
        <Col sm={24} md={14}>
          <img
            style={styles.bannerImage}
            alt="banner"
            src={image ? image : banner}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Banner

const styles = {
  welcomeContainer: {},
  container: {
    minHeight: "auto",
  },
  header: {
    position: "absolute",
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    paddingTop: 16,
  },
  layout: {
    flex: 1,
    backgroundColor: "#F0F1FF",
  },
  banner: {},
  bannerTextWrap: {},
  bannerTitle: {
    color: "#fff",
  },
  bannerText: {
    color: "#fff",
    fontSize: 20,
  },
  bannerImage: {
    maxWidth: "100%",
    height: "auto",
  },
  bannerButton: {
    fontSize: 24,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgGradient: {
    backgroundColor: Colors.brandPrimary,
    background: "linear-gradient(45deg, #5b2ad0 0%, #5b2ad0 50%, #0089ff 100%)",
  },
  textCenter: {
    textAlign: "center",
  },
  textWhite: {
    color: "#fff",
  },
  valuePropImg: {
    marginBottom: 48,
    width: "100%",
    maxWidth: 240,
  },
  valuePropTitle: {
    marginBottom: 24,
  },
  valuePropButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
  },
  section: {
    padding: 50,
    paddingBottom: 88,
    paddingTop: 80,
  },
  sectionTextWrap: {
    paddingTop: 80,
  },
  sectionTitle: {
    fontSize: 32,
    lineHeight: "32px",
  },
  sectionText: {
    fontSize: 20,
  },
  sectionImage: {
    maxWidth: "100%",
    height: "auto",
  },
  sectionButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
    paddingLeft: 40,
    paddingRight: 40,
  },
  sectionInput: {
    marginBottom: 24,
  },
}
